export default [
  {
    title: 'general.real_estates',
    icon: 'HomeIcon',
    children: [
      {
        title: 'general.real_estates_small',
        icon: 'HomeIcon',
        route: 'admin-real-estates-list'
      },
      {
        title: 'general.agents',
        icon: 'UsersIcon',
        route: 'real-estate-agents'
      }
    ]
  },
  {
    title: 'general.news',
    icon: 'FileIcon',
    route: 'admin-news-list'
  },
  {
    title: 'general.services',
    icon: 'BookIcon',
    children: [
      {
        title: 'general.services_small',
        icon: 'BookIcon',
        route: 'admin-service-list'
      },
      {
        title: 'general.service_providers',
        icon: 'UsersIcon',
        route: 'service-providers'
      }
    ]
  },
  {
    title: 'general.ads',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'general.ads_small',
        icon: 'FileTextIcon',
        route: 'admin-classified-ad-list'
      },
      {
        title: 'general.categories',
        icon: 'FileTextIcon',
        route: 'admin-category-list'
      }
    ]
  },
  {
    title: 'general.users',
    icon: 'UserIcon',
    children: [
      {
        title: 'general.administrators',
        icon: 'UsersIcon',
        route: 'admin-list'
      },
      {
        title: 'general.users_small',
        icon: 'UsersIcon',
        route: 'user-list'
      }
    ]
  },
  {
    title: 'general.advertisement',
    icon: 'ShoppingBagIcon',
    route: 'admin-advertisement-list'
  },
  {
    title: 'general.bulletin_board',
    icon: 'ClipboardIcon',
    route: 'bulletin-board'
  }
]
